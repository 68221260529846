import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Table,
    Row,
    Col,
    Badge,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Breadcrumb,
    BreadcrumbItem,
    Nav,
    NavItem,
    NavLink,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
    FormGroup,
    Label,
    InputGroupAddon,
    InputGroup,
} from 'reactstrap';
import { Icon } from '@iconify/react';
import homeCity from '@iconify/icons-mdi/home-city';
import accountBox from '@iconify/icons-mdi/account-box';
import accountIcon from '@iconify/icons-mdi/account';
import pillIcon from '@iconify/icons-mdi/pill';
import currencyUsd from '@iconify/icons-mdi/currency-usd';
import calendarMonth from '@iconify/icons-mdi/calendar-month';
import cashIcon from '@iconify/icons-mdi/cash';
import clipboardCheck from '@iconify/icons-mdi/clipboard-check';
import axios from 'axios';
import Loader from '../components/Loader';
import { UserContext } from '../contexts/UserContext';
import SimpleBar from 'simplebar-react';
import cashLock from '@iconify/icons-mdi/cash-lock';
import { Switch, Route } from 'react-router-dom';
import { useHistory, useLocation, Link } from 'react-router-dom';
import arrowTopRightBoldBoxOutline from '@iconify/icons-mdi/arrow-top-right-bold-box-outline';
import { SelectedFacilitiesContext } from '../contexts/SelectedFacilitiesContext';
import menuUpOutline from '@iconify/icons-mdi/menu-up-outline';
import menuDownOutline from '@iconify/icons-mdi/menu-down-outline';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import commentText from '@iconify/icons-mdi/comment-text';
import pillOff from '@iconify/icons-mdi/pill-off';
import TableRowDetailModal from './TableRowDetailModal';
import hospitalBox from '@iconify/icons-mdi/hospital-box';
import { SrxApi } from '../api/SrxApi';
import cloneDeep from 'lodash.clonedeep';
import classNames from 'classnames';
import noteTextOutline from '@iconify/icons-mdi/note-text-outline';
import noteMultipleOutline from '@iconify/icons-mdi/note-multiple-outline';
import {
    highCostAtomFilteredCount,
    highCostAtom,
    clarificationsAtomFilteredCount,
    clarificationsAtom,
    priorAuthAtomFilteredCount,
    priorAuthAtom,
    nonCoveredAtomFilteredCount,
    nonCoveredAtom,
    therapeuticIntAtomFilteredCount,
    therapeuticIntAtom,
    admissionMedsAtomFilteredCount,
    admissionMedsAtom,
    patMissingAtomFilteredCount,
    patMissingAtom,
} from '../jotai-atoms/AlertAtoms';
import {
    archivedHighCostAtom,
    archivedClarificationsAtom,
    archivedPriorAuthAtom,
    archivedNonCoveredAtom,
    archivedTherapeuticIntAtom,
    archivedAdmissionMedsAtom,
    archivedPatMissingAtom,
    archivedHighCostAtomFiltered,
    archivedClarificationsAtomFiltered,
    archivedPriorAuthAtomFiltered,
    archivedNonCoveredAtomFiltered,
    archivedTherapeuticIntAtomFiltered,
    archivedAdmissionMedsAtomFiltered,
    archivedPatMissingAtomFiltered,
} from '../jotai-atoms/ArchivedAlertAtoms';
import { useAtom } from 'jotai';
import { WidgetsData } from '../data/WidgetsData';
import { ActiveAlertContext } from '../contexts/ActiveAlertContext';
import FlatPicker from 'react-flatpickr';
import 'flatpickr/dist/themes/dark.css';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import LastUpdated from '../utility-components/LastUpdatedComponent';
import { SingleWidgetActiveTabContext } from '../contexts/SingleWidgetActiveTabContext';
import useRefreshAlerts from '../custom-hooks/useRefreshAlerts';
import refreshIcon from '@iconify/icons-mdi/refresh';
import transferRight from '@iconify/icons-mdi/transfer-right';
import chatIcon from '@iconify/icons-mdi/chat-processing-outline';
import { decode } from 'he';

function formatDate(inputDate) {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, '0');

    month = month.toString().padStart(2, '0');

    return `${year}-${month}-${date}`;
}

const SingleAlertWidget = (props) => {
    const { activeAlert, setActiveAlert } = useContext(ActiveAlertContext);

    const { activeTab, setActiveTab } = useContext(SingleWidgetActiveTabContext);

    // const [singleWidgetInfo, setSingleWidgetInfo] = useState(widgetInfo);

    const activeWidget = props.match.path.split('/')[2];

    const widgetData = WidgetsData.find((widget) => widget.path === activeWidget);

    const [data, setData] = useAtom(widgetData.atom);

    const [filteredAlerts, setFilteredAlerts] = useAtom(widgetData.atomFilteredAlerts);

    const [isModalOpen, setIsModalOpen] = useState(false);

    // const [activeTab, setActiveTab] = useState('current');

    const query = new URLSearchParams(props.location.search);

    useEffect(() => {
        setActiveTab('current');
    }, [props.location.search]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (activeAlert) setIsModalOpen(true);
        else setIsModalOpen(false);
    }, [activeAlert]);

    const toggleModal = () => {
        if (activeAlert) setActiveAlert(null);
        setIsModalOpen(!isModalOpen);
    };

    function getDateAndTime(date) {
        let onlyDate = new Date(date).toLocaleString();
        return onlyDate;
    }

    function MultiCommentCell(cell, id) {
        if (!cell) return '';

        return (
            <div
                id={`comment-popover-${id}`}
                className="d-flex justify-content-center"
                style={{ width: 'max-content' }}>
                <Icon icon={noteMultipleOutline} height="25" width="25" className="comment-icon" />
                <UncontrolledPopover
                    placement="top"
                    target={`comment-popover-${id}`}
                    trigger="hover"
                    style={{ maxHeight: '200px', overflow: 'auto' }}>
                    {/* <PopoverHeader>Title goes here</PopoverHeader> */}
                    {cell.map((comment, i) => (
                        <>
                            {i > 0 && i !== cell.length && <hr style={{ borderTop: '2px solid #eef2f7' }} />}
                            <PopoverBody>
                                <small>
                                    <i>{getDateAndTime(comment.loggedDateTime)}</i>
                                </small>
                                <br />
                                {comment.comment}
                            </PopoverBody>
                        </>
                    ))}
                </UncontrolledPopover>
            </div>
        );
    }

    function ReasonAndCommentCell(cell, id) {
        if (!cell) return '';

        return (
            <div
                id={`comment-popover-2-${id}`}
                className="d-flex justify-content-center"
                style={{ width: 'max-content' }}>
                <Icon icon={noteMultipleOutline} height="25" width="25" className="comment-icon" />
                <UncontrolledPopover
                    placement="top"
                    target={`comment-popover-2-${id}`}
                    trigger="hover"
                    style={{ maxHeight: '200px', overflow: 'auto' }}>
                    {cell.map((comment) => (
                        <>
                            <PopoverHeader>
                                <div className="mb-1">
                                    <u>{comment.qerDescription}</u>
                                </div>
                                <div>
                                    <small>
                                        <i>{getDateAndTime(comment.loggedDateTime)}</i>
                                    </small>
                                </div>
                            </PopoverHeader>
                            <PopoverBody>{comment.comment}</PopoverBody>
                        </>
                    ))}
                </UncontrolledPopover>
            </div>
        );
    }

    const { faciToken, setFaciToken } = useContext(UserContext);

    function getDate(date) {
        if (!date) return '';
        let onlyDate = new Date(date).toLocaleDateString();
        return onlyDate;
    }

    function capitalizeFirstLetter(word) {
        return word[0].toUpperCase() + word.substring(1);
    }

    const PriorAuthStatusBadge = (data) => {
        return (
            <Badge
                pill
                // color={data.toLowerCase() === 'pending' ? 'danger' : 'success'}
                className={`badge badge-outline-${data.toLowerCase() === 'pending' ? 'danger' : 'success'}`}>
                {capitalizeFirstLetter(data.toLowerCase())}
            </Badge>
        );
    };

    function formatCost(cost) {
        return <div>{cost && <> ${cost?.toLocaleString('en-US')}</>}</div>;
    }

    const ActionStatusBadge = (data) => {
        const getUserActionBadgeColor = (action = '') => {
            if (!action) return;
            if (action.toLowerCase() === 'approve') return 'success';
            if (action.toLowerCase() === 'deny') return 'danger';
            if (action.toLowerCase() === 'approve_once') return 'secondary';
            if (action.toLowerCase() === 'ask') return 'warning';
            else return 'primary';
        };

        const getUserActionLabel = (action = '') => {
            if (!action) return;
            if (action.toLowerCase() === 'approve') return 'Approved';
            if (action.toLowerCase() === 'deny') return 'Denied';
            if (action.toLowerCase() === 'approve_once') return 'Approved Once';
            else return action;
        };

        return (
            <Badge
                pill
                // color={data.toLowerCase() === 'pending' ? 'danger' : 'success'}
                className={'badge badge-outline-' + getUserActionBadgeColor(data)}>
                {getUserActionLabel(data)}
            </Badge>
        );
    };
    const ChatColumn = (data) => {
        const messages = data?.filter((i) => {
            return i.action === 'Reply';
        });
        let unRead = messages?.some((i) => i.readByFaci !== true);

        return (
            <span style={{ fontSize: '20px' }} hidden={!data?.length}>
                {' '}
                <Icon icon={chatIcon} className={`text-${unRead && 'danger'}`} />
            </span>
        );
    };
    // this is created as a function since the column format field expects a function
    const getArrayLengthWithBoldFont = (arr) => {
        return (
            <div className="w-100">
                <strong>{arr.length}</strong>
            </div>
        );
    };

    const patientNameCol = (data) => {
        return (
            <div className="col-truncate" title={data}>
                {data.toLowerCase()}
            </div>
        );
    };

    let singleWidgetInfoArray = [
        {
            columns: [
                {
                    name: 'Patient',
                    key: 'patName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                    className: '',
                },
                {
                    name: 'Drug',
                    key: 'drugLabelName',
                    sort: true,
                    icon: pillIcon,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Cost',
                    key: 'ttlPrice',
                    sort: true,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: formatCost,
                },
                {
                    name: 'Chat',
                    key: 'requestedActions',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ChatColumn,
                },
                {
                    name: 'Pharm Stat',
                    key: 'pharmacyStatus',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ActionStatusBadge,
                },
                {
                    name: 'Status',
                    key: 'srxStatus',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ActionStatusBadge,
                },
                // {
                //     name: 'Modified',
                //     key: 'lastModified',
                //     sort: false,
                //     icon: calendarMonth,
                //     prefix: '',
                //     suffix: '',
                //     format: getDate
                // },
                {
                    name: 'Alerted On',
                    key: 'dateCreated',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                },
            ],
            refreshSchedule: {
                mins: 0,
                scnds: 0,
            },
            name: 'Authorization',
            stateName: 'highCostList',
            defaultSortField: 'dateCreated',
            path: 'authorization',
            category: 'highCost',
            requestActionPermissionWidgetName: 'highCost',
            active: true,
        },
        {
            columns: [
                {
                    name: 'Patient',
                    key: 'patName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                    className: '',
                },
                {
                    name: 'Drug',
                    key: 'drugLabelName',
                    sort: true,
                    icon: pillIcon,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Cost',
                    key: 'ttlPrice',
                    sort: true,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: formatCost,
                },
                {
                    name: 'Chat',
                    key: 'requestedActions',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ChatColumn,
                },
                // {
                //     name: 'Pharm Stat',
                //     key: 'pharmacyStatus',
                //     sort: false,
                //     icon: null,
                //     prefix: '',
                //     suffix: '',
                //     format: ActionStatusBadge,
                // },
                {
                    name: 'Status',
                    key: 'srxStatus',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ActionStatusBadge,
                },
                // {
                //     name: 'Modified',
                //     key: 'lastModified',
                //     sort: false,
                //     icon: calendarMonth,
                //     prefix: '',
                //     suffix: '',
                //     format: getDate
                // },
                {
                    name: 'Alerted On',
                    key: 'dateCreated',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                },
            ],
            refreshSchedule: {
                mins: 0,
                scnds: 0,
            },
            name: 'High Cost Alert',
            stateName: 'highCostAlertsListFlat',
            defaultSortField: 'dateCreated',
            path: 'highcostalerts',
            category: 'highcostalerts',
            requestActionPermissionWidgetName: 'highcostalerts',
            active: true,
        },
        {
            columns: [
                {
                    name: 'Patient',
                    key: 'patName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Drug',
                    key: 'drugLabelName',
                    sort: true,
                    icon: pillIcon,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Chat',
                    key: 'requestedActions',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ChatColumn,
                },
                {
                    name: 'Sig',
                    key: 'sig',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: decode,
                },
                {
                    name: 'Comments',
                    key: 'openBatchCommentList',
                    sort: false,
                    icon: commentText,
                    prefix: '',
                    suffix: '',
                    format: ReasonAndCommentCell,
                    style: {
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    },
                },
                {
                    name: 'Alerted On',
                    key: 'dateCreated',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                },
            ],
            name: 'Clarifications',
            stateName: 'openBatchList',
            path: 'openBatch',
            category: 'clarifications',
            requestActionPermissionWidgetName: 'openBatch',
            defaultSortField: 'dateCreated',
            active: true,
            refreshSchedule: {
                mins: 6,
                scnds: 40,
            },
        },
        {
            columns: [
                {
                    name: 'Patient',
                    key: 'patName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Drug',
                    key: 'drugLabelName',
                    sort: true,
                    icon: pillIcon,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Cost',
                    key: 'ttlPrice',
                    sort: true,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: formatCost,
                },
                {
                    name: 'Chat',
                    key: 'requestedActions',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ChatColumn,
                },
                {
                    name: 'Comments',
                    key: 'nonCoveredCommentList',
                    sort: false,
                    icon: commentText,
                    prefix: '',
                    suffix: '',
                    format: MultiCommentCell,
                    // style: {
                    //     whiteSpace: 'nowrap',
                    //     textOverflow: 'ellipsis',
                    //     overflow: 'hidden'
                    // }
                },
                {
                    name: 'Alerted On',
                    key: 'dateCreated',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                },
            ],
            name: 'Non Covered',
            stateName: 'nonCoveredList',
            path: 'noncovered',
            category: 'nonCovered',
            requestActionPermissionWidgetName: 'nonCovered',
            defaultSortField: 'dateCreated',
            active: true,
            refreshSchedule: {
                mins: 1,
                scnds: 20,
            },
        },
        {
            columns: [
                {
                    name: 'Patient',
                    key: 'patName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Drug',
                    key: 'drugLabelName',
                    sort: true,
                    icon: pillIcon,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Cost',
                    key: 'ttlPrice',
                    sort: true,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: formatCost,
                },
                // {
                //     name: 'Billing Comment',
                //     key: 'billingComment',
                //     sort: false,
                //     icon: commentText,
                //     prefix: '',
                //     suffix: '',
                //     format: ''
                // },
                {
                    name: 'Chat',
                    key: 'requestedActions',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ChatColumn,
                },
                {
                    name: 'Status',
                    key: 'srxStatus',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: PriorAuthStatusBadge,
                },
                {
                    name: 'Alerted On',
                    key: 'dateCreated',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                },
            ],
            name: 'Prior Authorization Medications',
            stateName: 'priorAuthList',
            category: 'priorAuth',
            requestActionPermissionWidgetName: 'priorAuth',
            path: 'priorauth',
            defaultSortField: 'dateCreated',
            active: true,
            refreshSchedule: {
                mins: 2,
                scnds: 40,
            },
        },
        // refill too soon widget
        
        {
            columns: [
                {
                    name: 'Patient',
                    key: 'patName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Drug',
                    key: 'drugLabelName',
                    sort: true,
                    icon: pillIcon,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                // {
                //     name: 'Billing Comment',
                //     key: 'billingComment',
                //     sort: false,
                //     icon: commentText,
                //     prefix: '',
                //     suffix: '',
                //     format: ''
                // },
                {
                    name: 'Chat',
                    key: 'requestedActions',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ChatColumn,
                },
                {
                    name: 'Status',
                    key: 'srxStatus',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: PriorAuthStatusBadge,
                },
                {
                    name: 'Alerted On',
                    key: 'dateCreated',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                },
            ],
            name: 'Refill Too Soon',
            stateName: 'results',
            category: 'refillTooSoon',
            requestActionPermissionWidgetName: 'refillTooSoon',
            path: 'pharmacyAlerts',
            defaultSortField: 'dateCreated',
            active: true,
            refreshSchedule: {
                mins: 2,
                scnds: 40,
            },
        },
        {
            columns: [
                {
                    name: 'Patient',
                    key: 'patName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Prescribed Drug',
                    key: 'prescribedDrugName',
                    sort: true,
                    icon: pillIcon,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Drug Dispensed',
                    key: 'drugLabelName',
                    sort: true,
                    icon: pillIcon,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Chat',
                    key: 'requestedActions',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ChatColumn,
                },
                {
                    name: 'Alerted On',
                    key: 'dateCreated',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                },
            ],
            name: 'Therapeutic Interchanged',
            stateName: 'therapeuticInterchangeList',
            category: 'therapeuticInt',
            requestActionPermissionWidgetName: 'theraInt',
            path: 'therapeuticint',
            defaultSortField: 'dateCreated',
            active: true,
            refreshSchedule: {
                mins: 4,
                scnds: 0,
            },
        },
        {
            columns: [
                {
                    name: 'Patient',
                    key: 'patName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Admission Meds',
                    key: 'admissionMedsDetailsList',
                    sort: false,
                    icon: hospitalBox,
                    prefix: '',
                    suffix: '',
                    format: getArrayLengthWithBoldFont,
                },
                {
                    name: 'Chat',
                    key: 'requestedActions',
                    sort: false,
                    icon: null,
                    prefix: '',
                    suffix: '',
                    format: ChatColumn,
                },
                {
                    name: 'Admission Date',
                    key: 'admDate',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                },
            ],
            name: 'Admission / Medication Review',
            stateName: 'admissionMedsList',
            path: 'admissionmeds',
            requestActionPermissionWidgetName: 'admissionMeds',
            category: 'admissionMeds',
            defaultSortField: 'dateCreated',
            active: true,
            refreshSchedule: {
                mins: 5,
                scnds: 20,
            },
        },
        {
            columns: [
                {
                    name: 'Patient',
                    key: 'patName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Alerted On',
                    key: 'dateCreated',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                },
            ],
            // name: 'Patients Without Insurance',
            // stateName: 'patMissingList',
            // path: 'patmissing',
            // category: 'patMissing',
            // defaultSortField: 'dateCreated',
            // active: true,
            // refreshSchedule: {
            //     mins: 8,
            //     scnds: 0,
            // },
        },
    ];

    // this includes column display info. It will enevtually be combined with widgetData
    const widgetInfo = singleWidgetInfoArray.find((widget) => widget.path.toLowerCase() === activeWidget.toLowerCase());
    // TEST

    // !!!KEEP COMMENTED OUT

    // useEffect(() => {
    //     setTimeout(() =>
    //     setData({
    //         ...data,
    //         alertData:[
    //             ...data.alertData,
    //             {
    //                 dispenseDt: "2022-07-07T00:00:00-04:00",
    //                 drugLabelName: "TEST DRUG",
    //                 facID: "TTTTRRREEESSSAAADDDD",
    //                 facName: "TEST FACI 1",
    //                 patName: "ADNSJ, dsasda",
    //                 srxStatus: null,
    //                 ttlPrice: 562.44,
    //                 _generatedID: 432432324,
    //             },
    //             {
    //                 dispenseDt: "2022-07-07T00:00:00-04:00",
    //                 drugLabelName: "TEST DRUG",
    //                 facID: "TTTTRRREEESSSAAADDDD",
    //                 facName: "TEST FACI 1",
    //                 patName: "SAAK, dsadsaLIA",
    //                 srxStatus: null,
    //                 ttlPrice: 562.44,
    //                 _generatedID: 34223411,
    //             },
    //             {
    //                 dispenseDt: "2022-07-07T00:00:00-04:00",
    //                 drugLabelName: "TEST DRUG",
    //                 facID: "TTTTRRREEESSSAAADDDD",
    //                 facName: "TEST FACI 1",
    //                 patName: "NAME, NAME",
    //                 srxStatus: null,
    //                 ttlPrice: 562.44,
    //                 _generatedID: 9484,
    //             },
    //             {
    //                 dispenseDt: "2022-07-07T00:00:00-04:00",
    //                 drugLabelName: "TEST DRUG",
    //                 facID: "TTTTRRREEESSSAAADDDD",
    //                 facName: "TEST FACI 1",
    //                 patName: "NJASNJSA, 42434323",
    //                 srxStatus: null,
    //                 ttlPrice: 562.44,
    //                 _generatedID: 34223411,
    //             }
    //         ]
    //     }), 5 * 1000)
    // }, [])

    // useEffect(() => {
    //     // if(singleWidgetInfo.alerts.length)
    //         setFilteredByFacilityAlerts(filterByFacility((activeTab === 'current' ? singleWidgetInfo : archivedSingleWidgetInfo).alerts))
    // }, [singleWidgetInfo.alerts, activeTab])

    // useEffect(() => {
    //     // if(singleWidgetInfo.alerts.length)
    //         setFilteredByFacilityAlerts(filterByFacility((activeTab === 'current' ? singleWidgetInfo : archivedSingleWidgetInfo).alerts))
    // }, [selectedFacilities, activeTab])

    const [sortBy, setSortBy] = useState({
        sortField: widgetData.defaultSortField,
        sortDirection: 'asc',
    });

    function sortByField(array) {
        switch (sortBy.sortField) {
            // case 'dispenseDt':
            //     if (sortBy.sortDirection === 'asc') {
            //         return array
            //             .sort((a, b) => (a._generatedID > b._generatedID ? -1 : 1))
            //             .sort((a, b) => (new Date(a.dispenseDt) > new Date(b.dispenseDt) ? -1 : 1));
            //     } else {
            //         return array
            //             .sort((a, b) => (a._generatedID > b._generatedID ? -1 : 1))
            //             .sort((a, b) => (new Date(a.dispenseDt) < new Date(b.dispenseDt) ? -1 : 1));
            //     }
            case 'dateCreated':
                if (sortBy.sortDirection === 'asc') {
                    return array
                        .sort((a, b) => (a._generatedID > b._generatedID ? -1 : 1))
                        .sort((a, b) => (new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1));
                } else {
                    return array
                        .sort((a, b) => (a._generatedID > b._generatedID ? -1 : 1))
                        .sort((a, b) => (new Date(a.dateCreated) < new Date(b.dateCreated) ? -1 : 1));
                }
            case 'patName':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.patName < b.patName ? -1 : 1));
                else return array.sort((a, b) => (a.patName > b.patName ? -1 : 1));
            case 'facName':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.facName < b.facName ? -1 : 1));
                else return array.sort((a, b) => (a.facName > b.facName ? -1 : 1));
            case 'facID':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.facID < b.facID ? -1 : 1));
                else return array.sort((a, b) => (a.facID > b.facID ? -1 : 1));
            case 'drugLabelName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => (a.drugLabelName < b.drugLabelName ? -1 : 1));
                else return array.sort((a, b) => (a.drugLabelName > b.drugLabelName ? -1 : 1));
            case 'prescribedDrugName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => (a.prescribedDrugName < b.prescribedDrugName ? -1 : 1));
                else return array.sort((a, b) => (a.prescribedDrugName > b.prescribedDrugName ? -1 : 1));
            case 'ttlPrice':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.ttlPrice < b.ttlPrice ? -1 : 1));
                else return array.sort((a, b) => (a.ttlPrice > b.ttlPrice ? -1 : 1));
            case 'admDate':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => (new Date(a.admDate) > new Date(b.admDate) ? -1 : 1));
                else return array.sort((a, b) => (new Date(a.admDate) < new Date(b.admDate) ? -1 : 1));
            default:
                return array;
        }
    }

    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc',
            });
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc',
            });
        }
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ? (
                <Icon icon={arrowUp} color="black" />
            ) : (
                <Icon icon={arrowDown} color="black" />
            )}
        </>
    );

    const [rotateRefreshButton, setRotateRefreshButton] = useState(false);

    const { refreshWidget } = useRefreshAlerts();

    // useEffect(() => {
    //     let interval = setInterval(() => setCurrentTime(Date.now), 1 * 1000)
    //     return () => clearInterval(interval)
    // }, [])

    // function displayDifferenceInMinutes(rfrshTime){
    //     let differenceInMinutes = Math.floor((new Date(currentTime).getTime() - new Date(rfrshTime).getTime()) / 60000);

    //     if(differenceInMinutes < 1)
    //         return <small>Less than <span style={{ fontSize: '16px' }} ><b>1</b></span> minute ago</small>
    //     else if(differenceInMinutes === 1)
    //         return <small><span style={{ fontSize: '16px' }}><b>{differenceInMinutes}</b></span> minute ago</small>
    //     else if(differenceInMinutes >= 2 && differenceInMinutes <= 60)
    //         return <small><span style={{ fontSize: '16px' }}><b>{differenceInMinutes}</b></span> minutes ago</small>
    //     else
    //         return <small>More than <span style={{ fontSize: '16px' }} ><b>1</b></span> hour ago</small>
    // }

    // let differenceInMinutes = displayDifferenceInMinutes(data.lastRefreshed);

    // if(activeTab === 'current' ? data.loading : archivedData.loading){
    //     return(
    //         <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
    //         <div>
    //         <h4>Loading widget...</h4>
    //             <div className="bouncing-loader">
    //                 <div></div>
    //                 <div></div>
    //                 <div></div>
    //             </div>
    //             </div>
    //         </div>
    //     )
    // }
    return (
        <>
            <Row className="mt-2 mb-1  justify-content-between">
                <Col className="d-flex align-items-center">
                    <h3>{widgetInfo.name}</h3>
                    <Button
                        color="secondary"
                        className={`ml-2 py-0 px-1 d-flex align-items-center`}
                        style={{ right: '20px' }}
                        onClick={() => {
                            setRotateRefreshButton(true);
                            refreshWidget(widgetInfo.path);
                        }}
                        title="Refresh Alerts">
                        <Icon
                            className={`${rotateRefreshButton && 'refresh-widget-button-rotate'}`}
                            onAnimationEnd={() => setRotateRefreshButton(false)}
                            icon={refreshIcon}
                            width="25"
                        />
                    </Button>
                </Col>
                <Col sm={'auto'}>
                    <Button
                        color="dark"
                        onClick={() => {
                            props.history.push(props.location.pathname + '/archive');
                        }}>
                        <span className="mr-1">
                            <b>Archive</b>
                        </span>
                        <Icon icon={transferRight} width="30" />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card
                        style={{
                            minHeight: '410.61px',
                            // opacity: (activeTab === 'current' ? singleWidgetInfo : archivedSingleWidgetInfo).dataFilteredByFacilityLength.length > 0 ? '' : '.30',
                            // pointerEvents: (activeTab === 'current' ? singleWidgetInfo : archivedSingleWidgetInfo).alerts.length > 0 ? '' : 'none'
                            opacity: widgetInfo.active ? '' : '.30',
                            pointerEvents: widgetInfo.active ? '' : 'none',
                        }}>
                        {/* <Badge className="position-absolute ml-1 d-flex justify-content-center align-items-center bg-danger
                print-all-badge"
                >
                    { item.stateName === 'highCostAlerts'  && highCostAlerts.length }
                </Badge> */}
                        <div
                            className="d-flex justify-content-between mt-2 mx-3 align-items-center"
                            style={{ minHeight: '38px' }}>
                            <div className="d-flex">
                                <Badge className="py-1 px-2" color="light">
                                    <LastUpdated lastRefreshed={data.lastRefreshed} widgetPath={widgetInfo.path} />
                                </Badge>
                            </div>
                            <div style={{ fontSize: '16px' }}>
                                Total: <b>{filteredAlerts.length}</b>
                            </div>
                        </div>
                        <SimpleBar className="single-widget-height mx-2 my-2">
                            <CardBody className="pb-1 px-1 pt-0">
                                <Table hover size="sm" className="mb-1">
                                    <thead style={{ position: 'sticky', top: '0' }} className="bg-light alert-th">
                                        <tr style={{ overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '15px' }}>
                                            {widgetInfo.columns.map((col) => (
                                                <th
                                                    className={`py-2 position-relative ${col.sort ? '' : 'noHover'}`}
                                                    onClick={col.sort ? () => onClickSort(col.key) : null}>
                                                    {col.icon && <Icon icon={col.icon} />} {col.name}{' '}
                                                    {col.sort ? (
                                                        sortBy.sortField === col.key ? (
                                                            <SortCarets />
                                                        ) : (
                                                            <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {data.loading ? (
                                                <td colSpan={widgetInfo.columns.length}>
                                                    <div>
                                                        <h5 className="text-center">Loading Alerts</h5>
                                                        <div className="bouncing-loader">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </td>
                                            ) : (
                                                sortByField(filteredAlerts)
                                                    // .slice(0, numberOfAlertsToDisplay.highCostAlerts)
                                                    .map((alert, index) => (
                                                        <tr
                                                            key={alert.rxGuid}
                                                            style={{ fontSize: '13px' }}
                                                            className={classNames(
                                                                { unreadRow: !alert.readByFaci },
                                                                { readRow: alert.readByFaci },
                                                                { newRow: alert.displayStatus === 'new' },
                                                                { oldRow: alert.displayStatus === 'old' },
                                                                { deletedRow: alert.displayStatus === 'deleted' },
                                                                { approvedRow: alert.displayStatus === 'approved' },
                                                                {
                                                                    approvedOnceRow:
                                                                        alert.displayStatus === 'approvedOnce',
                                                                },
                                                                { deniedRow: alert.displayStatus === 'denied' },
                                                                { askedRow: alert.displayStatus === 'asked' }
                                                            )}
                                                            onClick={() => {
                                                                setActiveAlert({
                                                                    name: widgetInfo.name,
                                                                    alert: alert,
                                                                    stateName: widgetInfo.stateName,
                                                                    category: widgetInfo.category,
                                                                    requestActionPermissionWidgetName:
                                                                        widgetInfo.requestActionPermissionWidgetName,
                                                                    tab: activeTab,
                                                                });
                                                            }}>
                                                            {widgetInfo.columns.map((col) => (
                                                                <td
                                                                    style={{ verticalAlign: 'middle' }}
                                                                    className={col?.className}>
                                                                    {col.prefix}
                                                                    {col.format
                                                                        ? col.format(
                                                                            alert[col.key],
                                                                            alert.rxGuid,
                                                                            alert
                                                                        )
                                                                        : alert[col.key]}
                                                                    {col.suffix}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))
                                            )}
                                            {/* <tr>
                                <td colSpan="6" align="center">
                                    {highCostAlerts.length ?
                                    numOfAdditionalAlerts() > 0 ? <Button color="link" 
                                    onClick={() => add15Rows(item.stateName)}>
                                        <b>Show {numOfAdditionalAlerts()} more...</b>
                                    </Button>
                                    :
                                    <span><b>End of alerts</b></span>
                                    :
                                    <span><b>No alerts</b></span>
                                    }
                                </td>
                            </tr> */}
                                        </>
                                        {/* {item.alerts.map((alert, index) => {
                            return (
                                <tr key={index}>
                                    <td>{alert.patient}</td>
                                    <td>{alert.facility}</td>
                                    <td>{alert.drug }</td>
                                    <td>${alert.cost}</td>
                                    <td>{statusPill(alert.status)}</td>
                                    <td>{alert.date}</td>
                                </tr>
                            );
                        })} */}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </SimpleBar>
                    </Card>
                    {activeAlert && (
                        <TableRowDetailModal isOpen={isModalOpen} toggle={toggleModal} alertInfo={activeAlert} />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default SingleAlertWidget;
