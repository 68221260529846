import React, { useEffect } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody, Badge } from 'reactstrap';
import { Icon } from '@iconify/react';
import doctor from '@iconify/icons-mdi/doctor';
import homeCity from '@iconify/icons-mdi/home-city';
import medicineBottle from '@iconify/icons-mdi/medicine-bottle';
import accountIcon from '@iconify/icons-mdi/account';
import pillIcon from '@iconify/icons-mdi/pill';
import calendarMonth from '@iconify/icons-mdi/calendar-month';
import commentText from '@iconify/icons-mdi/comment-text';
import chatIcon from '@iconify/icons-mdi/chat-processing-outline';
import noteTextOutline from '@iconify/icons-mdi/note-text-outline';
import hospitalBox from '@iconify/icons-mdi/hospital-box';
import noteMultipleOutline from '@iconify/icons-mdi/note-multiple-outline';
import { useAtom } from 'jotai';
import {
    highCostAtom,
    highCostAtomFilteredCount,
    highCostAtomFiltered,
    highCostAtomShownAlerts,
    highCostAlertsAtom,
    highCostAlertsAtomFilteredCount,
    highCostAlertsAtomFiltered,
    highCostAlertsAtomShownAlerts,
    clarificationsAtom,
    clarificationsAtomFilteredCount,
    clarificationsAtomFiltered,
    clarificationsAtomShownAlerts,
    priorAuthAtom,
    priorAuthAtomFilteredCount,
    priorAuthAtomFiltered,
    priorAuthAtomShownAlerts,
    nonCoveredAtom,
    nonCoveredAtomFilteredCount,
    nonCoveredAtomFiltered,
    nonCoveredAtomShownAlerts,
    therapeuticIntAtom,
    therapeuticIntAtomFilteredCount,
    therapeuticIntAtomFiltered,
    therapeuticIntAtomShownAlerts,
    admissionMedsAtom,
    admissionMedsAtomFilteredCount,
    admissionMedsAtomFiltered,
    admissionMedsAtomShownAlerts,
    pharmacyAlertsAtomShownAlerts,
    pharmacyAlertsAtomFiltered,

    patMissingAtom,
    patMissingAtomFilteredCount,
    patMissingAtomFiltered,
    patMissingAtomShownAlerts,
    highCostAtomUserSettings,
    highCostAlertsAtomUserSettings,
    clarificationsAtomUserSettings,
    priorAuthAtomUserSettings,
    nonCoveredAtomUserSettings,
    therapeuticIntAtomUserSettings,
    admissionMedsAtomUserSettings,
    pharmacyAlertsAtomUserSettings,
    pharmacyAlertsAtom,
} from '../jotai-atoms/AlertAtoms';
import {
    archivedHighCostAtom,
    archivedHighCostAlertsAtom,
    archivedClarificationsAtom,
    archivedPriorAuthAtom,
    archivedNonCoveredAtom,
    archivedTherapeuticIntAtom,
    archivedAdmissionMedsAtom,
    archivedPatMissingAtom,
    archivedHighCostAtomFiltered,
    archivedHighCostAlertsAtomFiltered,
    archivedClarificationsAtomFiltered,
    archivedPriorAuthAtomFiltered,
    archivedNonCoveredAtomFiltered,
    archivedTherapeuticIntAtomFiltered,
    archivedAdmissionMedsAtomFiltered,
    archivedPatMissingAtomFiltered,
    archivedPharmacyAlertsAtom,
    archivedPharmacyAlertsAtomFiltered,
} from '../jotai-atoms/ArchivedAlertAtoms';
import { useState } from 'react';

function getDate(date) {
    if (!date) return '';
    let onlyDate = new Date(date).toLocaleDateString();
    return onlyDate;
}

function getDateAndTime(date) {
    let onlyDate = new Date(date).toLocaleString();
    return onlyDate;
}

// this is careted as a function since the column format field expects a function
const getArrayLengthWithBoldFont = (arr) => {
    return (
        <div className="w-100 text-center">
            <strong>{arr.length}</strong>
        </div>
    );
};

function CommentCell(cell, id) {
    if (!cell) return '';

    return (
        <div id={`comment-popover-${id}`} className="d-flex justify-content-center" style={{ width: 'max-content' }}>
            <Icon icon={noteTextOutline} height="25" width="25" className="comment-icon" />
            <UncontrolledPopover placement="top" target={`comment-popover-${id}`} trigger="hover">
                {/* <PopoverHeader>Title goes here</PopoverHeader> */}
                <PopoverBody>{cell}</PopoverBody>
            </UncontrolledPopover>
        </div>
    );
}

function MultiCommentCell(cell, id) {
    if (!cell) return '';

    return (
        <div id={`comment-popover-${id}`} className="d-flex justify-content-center" style={{ width: 'max-content' }}>
            <Icon icon={noteMultipleOutline} height="25" width="25" className="comment-icon" />
            <UncontrolledPopover
                placement="top"
                target={`comment-popover-${id}`}
                trigger="hover"
                style={{ maxHeight: '200px', overflow: 'auto' }}>
                {/* <PopoverHeader>Title goes here</PopoverHeader> */}
                {cell.map((comment, i) => (
                    <>
                        {i > 0 && i !== cell.length && <hr style={{ borderTop: '2px solid #eef2f7' }} />}
                        <PopoverBody>
                            <small>
                                <i>{getDateAndTime(comment.loggedDateTime)}</i>
                            </small>
                            <br />
                            {comment.comment}
                        </PopoverBody>
                    </>
                ))}
            </UncontrolledPopover>
        </div>
    );
}

function ReasonAndCommentCell(cell, id) {
    if (!cell) return '';

    return (
        <div id={`comment-popover-2-${id}`} className="d-flex justify-content-center" style={{ width: 'max-content' }}>
            <Icon icon={noteMultipleOutline} height="25" width="25" className="comment-icon" />
            <UncontrolledPopover
                placement="top"
                target={`comment-popover-2-${id}`}
                trigger="hover"
                style={{ maxHeight: '200px', overflow: 'auto' }}>
                {cell.map((comment) => (
                    <>
                        <PopoverHeader>
                            <div className="mb-1">
                                <b>{comment.qerDescription}</b>
                            </div>
                            <div>
                                <small>
                                    <i>{getDateAndTime(comment.loggedDateTime)}</i>
                                </small>
                            </div>
                        </PopoverHeader>
                        <PopoverBody>{comment.comment}</PopoverBody>
                    </>
                ))}
            </UncontrolledPopover>
        </div>
    );
}

function capitalizeFirstLetter(word) {
    return word[0].toUpperCase() + word.substring(1);
}

const PriorAuthStatusBadge = (data) => {
    return (
        <Badge
            pill
            // color={data.toLowerCase() === 'pending' ? 'danger' : 'success'}
            className={`badge badge-outline-${data.toLowerCase() === 'pending' ? 'danger' : 'success'}`}>
            {capitalizeFirstLetter(data.toLowerCase())}
        </Badge>
    );
};

const ActionStatusBadge = (data) => {
    const getUserActionBadgeColor = (action = '') => {
        if (!action) return;
        if (action.toLowerCase() === 'approve') return 'success';
        if (action.toLowerCase() === 'deny') return 'danger';
        if (action.toLowerCase() === 'approveonce') return 'secondary';
        if (action.toLowerCase() === 'ask') return 'warning';
        else return 'primary';
    };

    const getUserActionLabel = (action = '') => {
        if (!action) return;
        if (action.toLowerCase() === 'approve') return 'Approved';
        if (action.toLowerCase() === 'deny') return 'Denied';
        if (action.toLowerCase() === 'approveonce') return 'Approved Once';
        else return action;
    };

    return (
        <Badge
            pill
            // color={data.toLowerCase() === 'pending' ? 'danger' : 'success'}
            className={'badge badge-outline-' + getUserActionBadgeColor(data)}>
            {getUserActionLabel(data)}
        </Badge>
    );
};
const ChatColumn = (data) => {
    // console.log(data);
    const messages = data?.filter((i) => {
        return i.action === 'Reply';
    });
    // console.log(messages);
    let unRead = messages?.some((i) => i.readByFaci !== true);

    return (
        <span style={{ fontSize: '20px' }} hidden={!data?.length}>
            {' '}
            <Icon icon={chatIcon} className={`text-${unRead && 'danger'}`} />
        </span>
    );
};
const patientNameCol = (data) => {
    return (
        <div className="col-truncate" title={data}>
            {data.toLowerCase()}
        </div>
    );
};

const faciNameCol = (data, guid, row) => {
    return <div title={row.facID}>{data}</div>;
};

function formatCost(cost) {
    return <div>{cost && <> ${cost?.toLocaleString('en-US')}</>}</div>;
}

export const WidgetsData = [
    {
        name: 'authorization',
        stateName: 'highCostList',
        sidebarName: 'Authorization',
        path: 'authorization',
        category: 'highCost',
        requestActionPermissionWidgetName: 'highCost',
        defaultSortField: 'dateCreated',
        atom: highCostAtom,
        atomShownAlerts: highCostAtomShownAlerts,
        atomFilteredAlerts: highCostAtomFiltered,
        sortOrder: highCostAtomUserSettings.sortOrder,
        active: true,
        hidden: highCostAtomUserSettings.hidden,
        position: 0,
        archived: {
            atom: archivedHighCostAtom,
            atomFilteredAlerts: archivedHighCostAtomFiltered,
        },
        refreshSchedule: {
            mins: 0,
            scnds: 0,
        },
        columns: [
            {
                name: 'Patient',
                key: 'patName',
                sort: true,
                icon: accountIcon,
                prefix: '',
                suffix: '',
                format: patientNameCol,
            },
            {
                name: 'Facility',
                key: 'facID',
                sort: true,
                icon: homeCity,
                prefix: '',
                suffix: '',
                format: faciNameCol,
                className: 'faci-col',
            },
            {
                name: 'Drug',
                key: 'drugLabelName',
                sort: true,
                icon: pillIcon,
                prefix: '',
                suffix: '',
                format: '',
            },
            {
                name: 'Cost',
                key: 'ttlPrice',
                sort: true,
                icon: null,
                prefix: '',
                suffix: '',
                format: formatCost,
            },
            {
                name: null,
                key: 'requestedActions',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ChatColumn,
            },
            {
                name: 'Pharm Stat',
                key: 'pharmacyStatus',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ActionStatusBadge,
            },
            {
                name: 'Status',
                key: 'srxStatus',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ActionStatusBadge,
            },
            // {
            //     name: 'Modified',
            //     key: 'lastModified',
            //     sort: false,
            //     icon: calendarMonth,
            //     prefix: '',
            //     suffix: '',
            //     format: getDate
            // },
            {
                name: 'Alerted On',
                key: 'dateCreated',
                sort: true,
                icon: calendarMonth,
                prefix: '',
                suffix: '',
                format: getDate,
            },
        ],
    },
    {
        name: 'high cost alert',
        stateName: 'highCostAlertsListFlat',
        sidebarName: 'High Cost Alert',
        path: 'highcostalerts',
        category: 'highcostalerts',
        requestActionPermissionWidgetName: 'highcostalerts',
        defaultSortField: 'dateCreated',
        atom: highCostAlertsAtom,
        atomShownAlerts: highCostAlertsAtomShownAlerts,
        atomFilteredAlerts: highCostAlertsAtomFiltered,
        sortOrder: highCostAlertsAtomUserSettings.sortOrder,
        active: true,
        hidden: highCostAlertsAtomUserSettings.hidden,
        position: 0,
        archived: {
            atom: archivedHighCostAlertsAtom,
            atomFilteredAlerts: archivedHighCostAlertsAtomFiltered,
        },
        refreshSchedule: {
            mins: 0,
            scnds: 0,
        },
        columns: [
            {
                name: 'Patient',
                key: 'patName',
                sort: true,
                icon: accountIcon,
                prefix: '',
                suffix: '',
                format: patientNameCol,
            },
            {
                name: 'Facility',
                key: 'facID',
                sort: true,
                icon: homeCity,
                prefix: '',
                suffix: '',
                format: faciNameCol,
                className: 'faci-col',
            },
            {
                name: 'Drug',
                key: 'drugLabelName',
                sort: true,
                icon: pillIcon,
                prefix: '',
                suffix: '',
                format: '',
            },
            {
                name: 'Cost',
                key: 'ttlPrice',
                sort: true,
                icon: null,
                prefix: '',
                suffix: '',
                format: formatCost,
            },
            {
                name: null,
                key: 'requestedActions',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ChatColumn,
            },
            // {
            //     name: 'Pharm Stat',
            //     key: 'pharmacyStatus',
            //     sort: false,
            //     icon: null,
            //     prefix: '',
            //     suffix: '',
            //     format: ActionStatusBadge,
            // },
            {
                name: 'Status',
                key: 'srxStatus',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ActionStatusBadge,
            },
            // {
            //     name: 'Modified',
            //     key: 'lastModified',
            //     sort: false,
            //     icon: calendarMonth,
            //     prefix: '',
            //     suffix: '',
            //     format: getDate
            // },
            {
                name: 'Alerted On',
                key: 'dateCreated',
                sort: true,
                icon: calendarMonth,
                prefix: '',
                suffix: '',
                format: getDate,
            },
        ],
    },
    {
        name: 'clarifications',
        stateName: 'openBatchList',
        path: 'openbatch',
        category: 'clarifications',
        requestActionPermissionWidgetName: 'openBatch',
        atom: clarificationsAtom,
        atomShownAlerts: clarificationsAtomShownAlerts,
        atomFilteredAlerts: clarificationsAtomFiltered,
        position: 1,
        archived: {
            atom: archivedClarificationsAtom,
            atomFilteredAlerts: archivedClarificationsAtomFiltered,
        },
        defaultSortField: 'dateCreated',
        sidebarName: 'Clarifications',
        sortOrder: clarificationsAtomUserSettings.sortOrder,
        active: true,
        hidden: clarificationsAtomUserSettings.hidden,
        refreshSchedule: {
            mins: 6,
            scnds: 40,
        },
        columns: [
            {
                name: 'Patient',
                key: 'patName',
                sort: true,
                icon: accountIcon,
                prefix: '',
                suffix: '',
                format: patientNameCol,
            },
            {
                name: 'Facility',
                key: 'facID',
                sort: true,
                icon: homeCity,
                prefix: '',
                suffix: '',
                format: faciNameCol,
                className: 'faci-col',
            },
            {
                name: 'Drug',
                key: 'drugLabelName',
                sort: true,
                icon: pillIcon,
                prefix: '',
                suffix: '',
                format: '',
            },
            {
                name: null,
                key: 'requestedActions',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ChatColumn,
            },
            {
                name: 'Sig',
                key: 'sig',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: CommentCell,
            },
            {
                name: 'Comments',
                key: 'openBatchCommentList',
                sort: false,
                icon: commentText,
                prefix: '',
                suffix: '',
                format: ReasonAndCommentCell,
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                },
            },
            {
                name: 'Alerted On',
                key: 'dateCreated',
                sort: true,
                icon: calendarMonth,
                prefix: '',
                suffix: '',
                format: getDate,
            },
        ],
    },
    {
        name: 'non-covered',
        stateName: 'nonCoveredList',
        category: 'nonCovered',
        requestActionPermissionWidgetName: 'nonCovered',
        atom: nonCoveredAtom,
        atomShownAlerts: nonCoveredAtomShownAlerts,
        atomFilteredAlerts: nonCoveredAtomFiltered,
        archived: {
            atom: archivedNonCoveredAtom,
            atomFilteredAlerts: archivedNonCoveredAtomFiltered,
        },
        sidebarName: 'Non-Covered',
        path: 'nonCovered',
        active: true,
        hidden: nonCoveredAtomUserSettings.hidden,
        sortOrder: nonCoveredAtomUserSettings.sortOrder,
        position: 3,
        defaultSortField: 'dateCreated',
        refreshSchedule: {
            mins: 1,
            scnds: 20,
        },
        columns: [
            {
                name: 'Patient',
                key: 'patName',
                sort: true,
                icon: accountIcon,
                prefix: '',
                suffix: '',
                format: patientNameCol,
            },
            {
                name: 'Facility',
                key: 'facID',
                sort: true,
                icon: homeCity,
                prefix: '',
                suffix: '',
                format: faciNameCol,
                className: 'faci-col',
            },
            {
                name: 'Drug',
                key: 'drugLabelName',
                sort: true,
                icon: pillIcon,
                prefix: '',
                suffix: '',
                format: '',
            },
            {
                name: 'Cost',
                key: 'ttlPrice',
                sort: true,
                icon: null,
                prefix: '',
                suffix: '',
                format: formatCost,
            },
            {
                name: null,
                key: 'requestedActions',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ChatColumn,
            },
            {
                name: 'Comments',
                key: 'nonCoveredCommentList',
                sort: false,
                icon: commentText,
                prefix: '',
                suffix: '',
                format: MultiCommentCell,
                // style: {
                //     whiteSpace: 'nowrap',
                //     textOverflow: 'ellipsis',
                //     overflow: 'hidden'
                // }
            },
            {
                name: 'Alerted On',
                key: 'dateCreated',
                sort: true,
                icon: calendarMonth,
                prefix: '',
                suffix: '',
                format: getDate,
            },
        ],
    },
    {
        name: 'prior authorization medication',
        category: 'priorAuth',
        requestActionPermissionWidgetName: 'priorAuth',
        stateName: 'priorAuthList',
        atom: priorAuthAtom,
        atomShownAlerts: priorAuthAtomShownAlerts,
        atomFilteredAlerts: priorAuthAtomFiltered,
        archived: {
            atom: archivedPriorAuthAtom,
            atomFilteredAlerts: archivedPriorAuthAtomFiltered,
        },
        sidebarName: 'Prior Auth',
        path: 'priorAuth',
        active: true,
        hidden: priorAuthAtomUserSettings.hidden,
        sortOrder: priorAuthAtomUserSettings.sortOrder,
        position: 2,
        defaultSortField: 'dateCreated',
        refreshSchedule: {
            mins: 2,
            scnds: 40,
        },
        columns: [
            {
                name: 'Patient',
                key: 'patName',
                sort: true,
                icon: accountIcon,
                prefix: '',
                suffix: '',
                format: patientNameCol,
            },
            {
                name: 'Facility',
                key: 'facID',
                sort: true,
                icon: homeCity,
                prefix: '',
                suffix: '',
                format: faciNameCol,
                className: 'faci-col',
            },
            {
                name: 'Drug',
                key: 'drugLabelName',
                sort: true,
                icon: pillIcon,
                prefix: '',
                suffix: '',
                format: '',
            },
            {
                name: 'Cost',
                key: 'ttlPrice',
                sort: true,
                icon: null,
                prefix: '',
                suffix: '',
                format: formatCost,
            },
            {
                name: null,
                key: 'requestedActions',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ChatColumn,
            },
            // {
            //     name: 'Billing Comment',
            //     key: 'billingComment',
            //     sort: false,
            //     icon: commentText,
            //     prefix: '',
            //     suffix: '',
            //     format: CommentCell,
            //     style: {
            //         whiteSpace: 'nowrap',
            //         textOverflow: 'ellipsis',
            //         overflow: 'hidden'
            //     }
            // },
            {
                name: 'Status',
                key: 'srxStatus',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: PriorAuthStatusBadge,
            },
            {
                name: 'Alerted On',
                key: 'dateCreated',
                sort: true,
                icon: calendarMonth,
                prefix: '',
                suffix: '',
                format: getDate,
            },
        ],
    },
    {
        name: 'therapeutic interchanged',
        stateName: 'therapeuticInterchangeList',
        category: 'therapeuticInt',
        requestActionPermissionWidgetName: 'theraInt',
        path: 'therapeuticint',
        atom: therapeuticIntAtom,
        atomShownAlerts: therapeuticIntAtomShownAlerts,
        atomFilteredAlerts: therapeuticIntAtomFiltered,
        archived: {
            atom: archivedTherapeuticIntAtom,
            atomFilteredAlerts: archivedTherapeuticIntAtomFiltered,
        },
        sidebarName: 'Therapeutic Int',
        active: true,
        hidden: therapeuticIntAtomUserSettings.hidden,
        defaultSortField: 'dateCreated',
        sortOrder: therapeuticIntAtomUserSettings.sortOrder,
        position: 4,
        refreshSchedule: {
            mins: 4,
            scnds: 0,
        },
        columns: [
            {
                name: 'Patient',
                key: 'patName',
                sort: true,
                icon: accountIcon,
                prefix: '',
                suffix: '',
                format: patientNameCol,
            },
            {
                name: 'Facility',
                key: 'facID',
                sort: true,
                icon: homeCity,
                prefix: '',
                suffix: '',
                format: faciNameCol,
                className: 'faci-col',
            },
            {
                name: 'Prescribed Drug',
                key: 'prescribedDrugName',
                sort: true,
                icon: pillIcon,
                prefix: '',
                suffix: '',
                format: '',
            },
            {
                name: 'Drug Dispensed',
                key: 'drugLabelName',
                sort: true,
                icon: pillIcon,
                prefix: '',
                suffix: '',
                format: '',
            },
            {
                name: null,
                key: 'requestedActions',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ChatColumn,
            },
            {
                name: 'Alerted On',
                key: 'dateCreated',
                sort: true,
                icon: calendarMonth,
                prefix: '',
                suffix: '',
                format: getDate,
            },
        ],
    },
    {
        name: 'admission / medication review',
        stateName: 'admissionMedsList',
        category: 'admissionMeds',
        requestActionPermissionWidgetName: 'admissionMeds',
        atom: admissionMedsAtom,
        atomShownAlerts: admissionMedsAtomShownAlerts,
        atomFilteredAlerts: admissionMedsAtomFiltered,
        archived: {
            atom: archivedAdmissionMedsAtom,
            atomFilteredAlerts: archivedAdmissionMedsAtomFiltered,
        },
        path: 'admissionmeds',
        sidebarName: 'Admission Meds',
        active: true,
        hidden: admissionMedsAtomUserSettings.hidden,
        defaultSortField: 'dateCreated',
        sortOrder: admissionMedsAtomUserSettings.sortOrder,
        position: 5,
        refreshSchedule: {
            mins: 5,
            scnds: 20,
        },
        columns: [
            {
                name: 'Patient',
                key: 'patName',
                sort: true,
                icon: accountIcon,
                prefix: '',
                suffix: '',
                format: patientNameCol,
            },
            {
                name: 'Facility',
                key: 'facID',
                sort: true,
                icon: homeCity,
                prefix: '',
                suffix: '',
                format: faciNameCol,
                className: 'faci-col',
            },
            {
                name: 'Admission Meds',
                key: 'admissionMedsDetailsList',
                sort: false,
                icon: hospitalBox,
                prefix: '',
                suffix: '',
                format: getArrayLengthWithBoldFont,
            },
            {
                name: null,
                key: 'requestedActions',
                sort: false,
                icon: null,
                prefix: '',
                suffix: '',
                format: ChatColumn,
            },
            {
                name: 'Admission Date',
                key: 'admDate',
                sort: true,
                icon: calendarMonth,
                prefix: '',
                suffix: '',
                format: getDate,
            },
        ],
    },
    {
        name: 'refill too soon',
        stateName: 'results',
        category: 'refillTooSoon',
        path: 'pharmacyalerts/refilltoosoon',
        requestActionPermissionWidgetName: 'refillTooSoon',
        defaultSortField: 'dateCreated',
        atom: pharmacyAlertsAtom,
        atomShownAlerts: pharmacyAlertsAtomShownAlerts,
        atomFilteredAlerts: pharmacyAlertsAtomFiltered,
        sortOrder: pharmacyAlertsAtomUserSettings.sortOrder,
        active: true,
        hidden: pharmacyAlertsAtomUserSettings.hidden,
        position: 0,
        archived: {
            atom: archivedPharmacyAlertsAtom,
            atomFilteredAlerts: archivedPharmacyAlertsAtomFiltered,
        },
        refreshSchedule: {
            mins: 0,
            scnds: 0,
        },
        total: 0,
        hasMore: false,
        // alerts: [],
        // displayedAlerts: [],
        columns: [    
            {
                name: 'Patient',
                key: 'patientName',
                sort: false,
                icon: accountIcon,
                prefix: '',
                suffix: '',
                format: '',
            },
            {
                name: 'Nursing Station',
                key: 'nursingStationId',
                sort: false,
                icon: doctor,
                prefix: '',
                suffix: '',
                format: '',
                className: 'faci-col',
            },
            {
                name: 'Drug Name',
                key: 'drugLabelName',
                sort: false,
                icon: medicineBottle,
                prefix: '',
                suffix: '',
                format: '',
                className: 'faci-col',
            },
            {
                name: 'Due Date',
                key: 'dueOn',
                sort: false,
                icon: calendarMonth,
                prefix: '',
                suffix: '',
                format: getDate,
                className: 'faci-col',
            },
        ]
    },
    // {
    //     name: 'controlled rx - 0 qty remaining alerts',
    //     alerts: [],
    //     displayedAlerts: [],
    //     columns: []
    // },
    // {
    //     name: 'meds not returned for credit alerts',
    //     alerts: [],
    //     displayedAlerts: [],
    //     columns: []
    // },
    // {
    //     name: 'patients without insurance',
    //     sortOrder: 7,
    //     stateName: 'patMissingList',
    //     category: 'patMissing',
    //     atom: patMissingAtom,
    //     atomShownAlerts: patMissingAtomShownAlerts,
    //     atomFilteredAlerts: patMissingAtomFiltered,
    //     archived:{
    //         atom: archivedPatMissingAtom,
    //         atomFilteredAlerts: archivedPatMissingAtomFiltered,
    //     },
    //     path: 'patmissing',
    //     sidebarName: 'Pats W/O Insurance',
    //     active: true,
    //     defaultSortField: 'dateCreated',
    //     refreshSchedule: {
    //         mins: 8,
    //         scnds: 0,
    //     },
    //     alerts: [],
    //     displayedAlerts: [],
    //     columns: [
    //         {
    //             name: 'Patient',
    //             key: 'patName',
    //             sort: true,
    //             icon: accountIcon,
    //             prefix: '',
    //             suffix: '',
    //             format: patientNameCol
    //         },
    //         {
    //             name: 'Facility',
    //             key: 'facID',
    //             sort: true,
    //             icon: homeCity,
    //             prefix: '',
    //             suffix: '',
    //             format: faciNameCol,
    //             className: 'faci-col'
    //         },
    //         {
    //             name: 'Alerted On',
    //             key: 'dateCreated',
    //             sort: true,
    //             icon: calendarMonth,
    //             prefix: '',
    //             suffix: '',
    //             format: getDate
    //         }
    //     ]
    // }
];