import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils'

let defaultState = {
    loading: true,
    alertData: [],
    dateRange: {
        from: null,
        to: null
    },
    // this is needed because often the new data is displayed together with the old data where the new data is being transitioned in and the old data is being removed 
    // shownAlerts: [],
    error: null,
    lastRefreshed: null
}

export const archivedHighCostAtom = atomWithReset(defaultState);
export const archivedHighCostAlertsAtom = atomWithReset(defaultState);
export const archivedClarificationsAtom = atomWithReset(defaultState);
export const archivedPriorAuthAtom = atomWithReset(defaultState);
export const archivedNonCoveredAtom = atomWithReset(defaultState);
export const archivedTherapeuticIntAtom = atomWithReset(defaultState);
export const archivedAdmissionMedsAtom = atomWithReset(defaultState);
export const archivedPatMissingAtom = atomWithReset(defaultState);
export const archivedPharmacyAlertsAtom = atomWithReset(defaultState);

export const archivedHighCostAtomFilteredCount = atomWithReset(null);
export const archivedHighCostAlertsAtomFilteredCount = atomWithReset(null);
export const archivedClarificationsAtomFilteredCount = atomWithReset(null);
export const archivedPriorAuthAtomFilteredCount = atomWithReset(null);
export const archivedNonCoveredAtomFilteredCount = atomWithReset(null);
export const archivedTherapeuticIntAtomFilteredCount = atomWithReset(null);
export const archivedAdmissionMedsAtomFilteredCount = atomWithReset(null);
export const archivedPatMissingAtomFilteredCount = atomWithReset(null);
export const archivedPharmacyAlertsAtomFilteredCount = atomWithReset(null);

export const archivedHighCostAtomFiltered = atomWithReset([]);
export const archivedHighCostAlertsAtomFiltered = atomWithReset([]);
export const archivedClarificationsAtomFiltered = atomWithReset([]);
export const archivedPriorAuthAtomFiltered = atomWithReset([]);
export const archivedNonCoveredAtomFiltered = atomWithReset([]);
export const archivedTherapeuticIntAtomFiltered = atomWithReset([]);
export const archivedAdmissionMedsAtomFiltered = atomWithReset([]);
export const archivedPatMissingAtomFiltered = atomWithReset([]);
export const archivedPharmacyAlertsAtomFiltered = atomWithReset([]);